/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FirmData } from '../types';
import * as API from '../api/account';
import * as RamateAPI from '../api/ramateVerify';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<FirmData>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('ramate_crm_auth') || '');

	const { data } = useSWR(
		user ? 'auth' : null,
		() => {
			const parseData = JSON.parse(user);
			// console.log('parseData', parseData);
			parseData.firmId === '' && parseData.token && parseData.userId
				? RamateAPI.adminAutoLogin({ ramateAccountId: parseData.userId, ramateAccountToken: parseData.token })
						.then((res) => {
							dispatch({ type: 'FIRM_ADMIN_LOGIN', payload: { ...res, deviceId: parseData.deviceId } });
						})
						.catch((err) => {
							if (err.status === 400) {
								dispatch({ type: 'FIRM_LOGOUT' });
							}
						})
				: API.autoLogin(parseData)
						.then((res) => {
							// 自動登入應該保持原有的platform 所以不用重新判斷
							// let platform = '';
							// if (res.data.platformAuthority?.bombcourse) {
							// 	platform = '1'; //沒有bombmy權限時預設以幫開課為主
							// }
							// if (res.data.platformAuthority?.bombmy) {
							// 	platform = '0'; //預設以幫賣為主
							// }
							dispatch({
								type: 'FIRM_LOGIN',
								payload: {
									...res.data,
									deviceId: JSON.parse(user).deviceId,
									csrf: res.headers.get('Grpc-Metadata-Csrf-Token'),
									platform: parseData.platform,
									// CSRF token(模擬過期用)
									// csrf: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJtX2lkIjoiOGI3MGQ4MDAtYzVmNS00Y2FhLTkyOGMtMWIwN2Q0ZDIxNGUzIiwidXNlcl9pZCI6IjI0ODQ0YzViLTc5NzItNGI4Ni04MWZiLTdhZThmMjk5MTE3NSIsImF1ZCI6IjI0ODQ0YzViLTc5NzItNGI4Ni04MWZiLTdhZThmMjk5MTE3NSIsImV4cCI6MTY4MzUzNzQzMCwiaWF0IjoxNjgzNTM3MzcwLCJpc3MiOiJyYW1hdGV0ZWNoIn0.5S1Td9dIuzI7bD12WR-xWZHRd4j7DPPShnrGoIEuZ0s',
								},
							});
						})
						.catch((err) => {
							if (err.status === 400) {
								dispatch({ type: 'FIRM_LOGOUT' });
							} else if (err.status === 503) {
								// dispatch({ type: 'FIRM_LOGOUT' });
								window.location.reload();
							}
						});
		},
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			shouldRetryOnError: false,
			errorRetryInterval: 0,
			errorRetryCount: 5,
		},
	);

	const [userData, setUserData] = useState<Partial<FirmData>>({});
	const dispatch = useDispatch();

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
