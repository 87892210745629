import { Api as backendApis } from '../swagger/backend';
import { Api as thirdPartyApis } from '../swagger/thirdParty';
import Bottleneck from 'bottleneck';
import store from '../redux/configureStore';

const limiter = new Bottleneck({
	maxConcurrent: 3,
	minTime: 100,
});

// function relogin(res: Response, input: RequestInfo | URL, init: RequestInit | undefined) {
// 	return backendApi.firmAutoLogin
// 		.crmFirmAutoLogin({
// 			firmId: store.getState().user.firmInfo.firmId,
// 			userId: store.getState().user.userInfo.userId,
// 			token: store.getState().user.userInfo.token,
// 			deviceId: store.getState().user.deviceId,
// 		})
// 		.then((loginRes) => {
// 			const headers = loginRes.headers;
// 			// console.log('重新取得成功', headers.get('Grpc-Metadata-Csrf-Token'));
// 			// console.log('重新取得成功', headers.get('Content-type'));
// 			// console.log('重新取得成功', headers.get('Content-length'));
// 			store.dispatch({
// 				type: 'FIRM_LOGIN',
// 				payload: {
// 					...loginRes.data,
// 					deviceId: store.getState().user.deviceId,
// 					csrf: headers.get('Grpc-Metadata-Csrf-Token'),
// 				},
// 			});
// 			fetch(input, {
// 				...init,
// 				headers: {
// 					Authorization: store.getState().user.userInfo.authorizationToken,
// 					'Grpc-Metadata-Csrf-Token': headers.get('Grpc-Metadata-Csrf-Token') || '',
// 					'Grpc-Metadata-Firm_Id': store.getState().user.firmInfo.firmId,
// 					'Grpc-Metadata-User_Id': store.getState().user.userInfo.userId,
// 					// Accept: 'application/grpc, application/json, text/plain, */*',
// 				},
// 			})
// 				.then((res2) => {
// 					console.log('46 resolve', res2);
// 					return Promise.resolve(res2);
// 				})
// 				.catch((res2) => {
// 					return Promise.resolve(res2);
// 				});
// 		})
// 		.catch((err) => {
// 			showNotification('error', '登入失敗', '請重新登入');
// 			if (err.status === 400) {
// 				store.dispatch({ type: 'FIRM_LOGOUT' });
// 				return Promise.resolve(res);
// 			} else if (err.status === 503) {
// 				window.location.reload();
// 				return Promise.resolve(res);
// 			} else {
// 				return Promise.resolve(res);
// 			}
// 		});
// }

// function fetchFunc(input: URL | RequestInfo, init?: RequestInit) {
// 	return fetch(input, {
// 		...init,
// 		headers: {
// 			Authorization: store.getState().user.userInfo.authorizationToken,
// 			'Grpc-Metadata-Csrf-Token': store.getState().user.csrf,
// 			'Grpc-Metadata-Firm_Id': store.getState().user.firmInfo.firmId,
// 			'Grpc-Metadata-User_Id': store.getState().user.userInfo.userId,
// 			// Accept: 'application/grpc, application/json, text/plain, */*',
// 		},
// 	})
// 		.then(async (res) => {
// 			const response = res.clone();
// 			const json = await response.json();
// 			console.log('then ', json);

// 			if (json?.code === 16) {
// 				relogin(res, input, init);
// 			} else {
// 				console.log('json code is not 16: ', res);
// 				return Promise.resolve(res);
// 			}
// 		})
// 		.catch(async (res) => {
// 			const response = res.clone();
// 			const json = await response.json();
// 			console.log('catch ', json);

// 			if (json?.code === 16) {
// 				relogin(res, input, init);
// 			} else {
// 				console.log('json code is not 16: ', res);
// 				return Promise.resolve(res);
// 			}
// 		});
// }

const customFetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
	// 正常執行 REQUEST
	const response = await fetch(input, {
		...init,
		headers: {
			...init?.headers,
			Authorization: store.getState().user.userInfo.authorizationToken,
			'Grpc-Metadata-Csrf-Token': store.getState().user.csrf,
			'Grpc-Metadata-Firm_Id': store.getState().user.firmInfo.firmId,
			'Grpc-Metadata-User_Id': store.getState().user.userInfo.userId,
		},
	});

	const responseClone = response.clone();
	const json = await responseClone.json();

	if (json?.code === 16) {
		// CSRF 過期，重新登入取得新的 CSRF
		try {
			const csrfResponse = await backendApi.firmAutoLogin.crmFirmAutoLogin({
				firmId: store.getState().user.firmInfo.firmId,
				userId: store.getState().user.userInfo.userId,
				token: store.getState().user.userInfo.token,
				deviceId: store.getState().user.deviceId,
			});
			const newCsrf = csrfResponse.headers.get('Grpc-Metadata-Csrf-Token');
			// 取得了新的CSRF
			let platform = '';
			if (csrfResponse.data.platformAuthority?.bombcourse) {
				platform = '1'; //沒有bombmy權限時預設以幫開課為主
			}
			if (csrfResponse.data.platformAuthority?.bombmy) {
				platform = '0'; //預設以幫賣為主
			}
			store.dispatch({
				type: 'FIRM_LOGIN',
				payload: {
					...csrfResponse.data,
					deviceId: store.getState().user.deviceId,
					csrf: newCsrf,
					platform: platform,
				},
			});

			// 重新設定 headers
			const newHeaders = {
				...init?.headers,
				'Grpc-Metadata-Csrf-Token': newCsrf || '',
			};
			const newInit = {
				...init,
				headers: newHeaders,
			};

			// 重新跑一次原本的 REQUEST
			return await customFetch(input, newInit);
		} catch (err: any) {
			// 重新登入失敗，清除登入資訊
			if (err.error.code === 3) {
				store.dispatch({ type: 'FIRM_LOGOUT' });
			}
			return err;
		}
	} else if (json.code === 12) {
		// store.dispatch({ type: 'FIRM_LOGOUT' });
		return response;
	} else {
		return response;
	}
};

export const backendApi = new backendApis({
	baseUrl: 'https://ramate-crm-backend-gmt4o4nvya-de.a.run.app',
	// baseUrl: 'https://ramate-crm-backend-beta-gmt4o4nvya-de.a.run.app',
	customFetch(input, init?) {
		return limiter.schedule(() => customFetch(input, init));
	},
	// customFetch(input, init?) {
	// 	return limiter.schedule(() => fetchFunc(input, init));
	// },
});

// export const backendApi = new backendApis({
// 	baseUrl: 'https://ramate-crm-backend-gmt4o4nvya-de.a.run.app',
// 	customFetch(input, init?) {
// 		const state = store.getState();
// 		const auth = state?.user?.userInfo?.authorizationToken;
// 		if (auth && auth !== '') {
// 			return limiter.schedule(() => fetch(input, { ...init, headers: { Authorization: auth } }));
// 		} else {
// 			// 沒有 auth 的處理方式
// 			return Promise.reject(new Error('沒有 authorizationToken'));
// 		}
// 	},
// });

export const thirdPartyApi = new thirdPartyApis({
	baseUrl: 'https://ramate-crm-third-party-gmt4o4nvya-de.a.run.app',
	// baseUrl: 'https://ramate-crm-third-party-beta-gmt4o4nvya-de.a.run.app',
	customFetch(input, init?) {
		return limiter.schedule(() => fetch(input, { ...init, headers: { Authorization: store.getState().user.userInfo.authorizationToken } }));
	},
});
